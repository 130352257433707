import * as React from "react";
import { useState } from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ReactMarkdown from 'react-markdown';

import Seo from "../components/Seo";
import Sidebar from "../components/Sidebar";
import ProxyModal from "../components/proxy-votes";
import SidebarWidget from "../components/SidebarWidget";
import PostalPDFModal from "../components/PostalPDFModal";
import CandidateModal from "../components/CandidateModal";
import WardResultHero from "../components/WardResultHero";
import CandidateProfile from "../components/CandidateProfile";
import WardElectionsHero from "../components/WardElectionsHero";
import WardNoElectionsHero from "../components/WardNoElectionsHero";
import SignUpSection from "../components/SignUpSection";

export default function Ward({ data }) {
  const [modalFormOpen, setModalFormOpen] = useState(false);
  const [modalProxyOpen, setModalProxyOpen] = useState(false);
  const [modalCandidateOpen, setModalCandidateOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});

  const { frontmatter } = data.ward;
  
  const wardName = frontmatter.ward_name;
  const wardImage = frontmatter.ward_image;
  const wardCandidates = frontmatter.ward_candidates;
  const wardIntroduction = frontmatter.ward_introduction;
  const candidatesNumber = frontmatter.candidates_number;
  const wardVotingPlaces = frontmatter.ward_voting_places;
  const thereAreElections = frontmatter.there_are_elections;
  const showCandidates = frontmatter.show_candidates_profiles;
  const meetYourCandidates = frontmatter.meet_your_candidates;
  const candidatesSubtitle = frontmatter.candidates_section_subtitle;
  const headerInformation = frontmatter.header_information;
  const wardElectionDate = frontmatter.ward_election_date;
  const electionType = frontmatter.election_type;
  const noElectionsMessage = frontmatter.no_elections_message;

  // = = = = = = = = = = = = =
  // Event Handlers

  const handleClick = (e) => {
    e.preventDefault();
    e.target.id === 'pdf-form' && setModalFormOpen(true);
    e.target.id === 'proxy-modal' && setModalProxyOpen(true);
  }

  return(
    <>
      <Seo title="Ward" />

      { modalFormOpen && <PostalPDFModal setModalOpen={setModalFormOpen} />}
      { modalProxyOpen && <ProxyModal setModalOpen={setModalProxyOpen} />}
      { modalCandidateOpen && <CandidateModal candidate={selectedCandidate} ward={wardName} meetInfo={meetYourCandidates} setModalOpen={setModalCandidateOpen}/>}

      {
        thereAreElections ?
          <WardElectionsHero 
            wardName={wardName} 
            wardImage={wardImage}
            wardElectionDate={wardElectionDate}
            candidatesNumber={candidatesNumber}
            wardVotingPlaces={wardVotingPlaces}
            handleClick={handleClick}
            headerInformation={headerInformation}
            electionType={electionType}
          />
        :
        <WardNoElectionsHero
          wardName={wardName}
          wardImage={wardImage}
          noElectionsMessage={noElectionsMessage}
        />
      }

      <div className="page-content">
        <div className="container">
          <div className="row">

            {
              <div className="col-md-3">
                <Sidebar 
                  // headings = { data.markdownRemark.headings } 
                  // links = { aboutData.sidebar_links }
                >
                    
                  {/* <SidebarWidget 
                    headline="Apply for a postal vote"
                    linkLabel="Must be received by 5pm on 9th March"
                    link="/how-to-vote"
                  >
                    <StaticImage 
                      className="sidebar__widget__image" 
                      src="../images/sidebar-widget-vote.jpg" 
                      alt=""
                    />
                  </SidebarWidget> */}

                  <SidebarWidget 
                    headline="Stand for the City"
                    linkLabel="Become a Candidate"
                    link="/become-a-councillor"
                  >
                    <StaticImage 
                      className="sidebar__widget__image" 
                      src="../images/sidebar-widget-councillor.jpg" 
                      alt=""
                    /> 
                  </SidebarWidget>

                </Sidebar>
              </div>
            }

            <div className="col-md-9">

              <div className="page__introduction">
                <h2>Introduction</h2>
                <ReactMarkdown>{wardIntroduction}</ReactMarkdown>
              </div>

              {
                showCandidates && wardCandidates
                  &&
                <div className="page__candidates">
                  <h2>Your Candidates for {wardName}</h2>
                  {
                    candidatesSubtitle
                    &&
                    <>
                      <h3>{candidatesSubtitle}</h3>
                      <span className="headline-underline"/>
                    </>
                  }
                  <div className="candidates">
                    {
                      !showCandidates ?
                      <p>Details of your candidates will be published here later soon after the close of nominations.</p>
                      
                      :
                        wardCandidates.map(candidate => (
                          <CandidateProfile
                            candidate={candidate}
                            setModalCandidateOpen={setModalCandidateOpen}
                            setSelectedCandidate={setSelectedCandidate}
                          />
                        ))
                    }
                  </div>
                  {/* <div className="candidates">
                    {
                      !showCandidates ?
                      <p>Details of your candidates will be published here later this week. You can see who has been nominated by viewing the <a href="https://www.cityoflondon.gov.uk/about-us/voting-elections/statements-of-persons-nominated">Statements of Persons Nominated</a>.</p>
                      
                      :
                        wardCandidates.map(candidate => (
                          <CandidateProfile
                            candidate={candidate}
                            setModalCandidateOpen={setModalCandidateOpen}
                            setSelectedCandidate={setSelectedCandidate}
                          />
                        ))
                    }
                  </div> */}

                </div>
              }

              {
                showCandidates
                  &&
                <div id="meet-your-candidates" className="page__candidates-meeting">
                  <h2>Meet your Candidates</h2>
                  <ReactMarkdown className="meeting-text">{meetYourCandidates}</ReactMarkdown>
                </div>
              }
              
            </div>

          </div>
        </div>
      </div>

      <SignUpSection />
    </>
  )
}


export const query = graphql`
  query Ward($id: String!) {
    ward: markdownRemark(id: {eq: $id}) {
      frontmatter {
        ward_name
        ward_introduction
        candidates_section_subtitle
        show_candidates_profiles
        there_are_elections
        election_type
        no_elections_message
        header_information{
          by_post_message
          show_by_post_button
          proxy_voter_message
          show_proxy_voter_button
        }
        ward_election_date
        ward_candidates {
          candidate_statement
          candidate_name
          candidate_party
          candidate_profile_picture {
            childImageSharp {
              gatsbyImageData
            }
          }
          telephone_number
          candidate_email
          website
          twitter
          linkedin
          social_media {
            media_label
            media_url
          }
        }
        ward_voting_places {
          location_address
          location_url
          location_name
          type
        }
        ward_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        meet_your_candidates
        candidates_number
      }
    }
  }
`;